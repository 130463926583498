

export const Carddata_head_section = [
    {
        title: {
            en: "Your choice - which camp is your flavour?",
            nl: "Jouw keuze - welk kamp is jouw smaak?",
            du: "Uw keuze - welk kamp is uw favoriet?"
        },
        subtitle: {
            en: "Kiteactive",
            nl: "Kiteactive",
            du: "Kiteactive"
        },
        subtitle1: {
            en: "- for Adventures: Our Top Camps of 2024!",
            nl: "- voor avonturen: Onze beste kampen van 2024!",
            du: "- voor avonturen: Onze populairste kampen van 2024!"

        }
    }

];

