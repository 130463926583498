import vedio from '../../Images/website.filmpje.tarifa.mp4';
import img1 from '../../Images/kc1.webp';
import img2 from '../../Images/kc2.webp';
import img3 from '../../Images/kc3.webp';
import img4 from '../../Images/kc4.webp';
import img5 from '../../Images/kc5.webp';
import img6 from '../../Images/kc6.png';
import img7 from '../../Images/kc7.webp';
import img8 from '../../Images/kc8.webp';
import img9 from '../../Images/kc9.webp';
import img10 from '../../Images/kc10.webp';

export const cardData = [
    { id: 1, img: img1, path: "/trip/vanlife", trip_name: "Vanlife", vedio: vedio },
    { id: 2, img: img2, path: "/trip/zanzibar", trip_name: "zanzibar", vedio: vedio },
    { id: 3, img: img3, path: "/trip/Snowkite", trip_name: "Snowkite", vedio: vedio },
    { id: 4, img: img4, path: "/trip/skyCapTown", trip_name: "skyCapTown", vedio: vedio },
    { id: 5, img: img5,  path: "/trip/KitemeccaTraifa", trip_name: "KitemeccaTraifa", vedio: vedio },
    { id: 6, img: img6,  path: "/trip/EndlessSummerParty", trip_name: "EndlessSummerParty", vedio: vedio  },
    { id: 7, img: img7,  path: "/trip/Brazil", trip_name: "Brazil", vedio: vedio },
    { id: 8, img: img8,  path: "/trip/Dakhla", trip_name: "Dakhla", vedio: vedio },
    { id: 9, img: img9,  path: "/trip/Sail&kite", trip_name: "Sail&kite", vedio: vedio },
    { id: 10, img: img10,  path: "/trip/Sicily", trip_name: "Sicily", vedio: vedio },
];
